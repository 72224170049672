<template>
  <div
    class="flex items-center justify-center flex-col max-w-full h-40 border border-dashed border-gray-300 bg-gray-50 lg:h-52"
    @drop.prevent="addFileFromDragEvent"
    @dragenter.prevent
    @dragover.prevent
  >
    <div
      class="flex items-center justify-center w-16 h-16 bg-blue-100 rounded-full mb-2"
    >
      <div
        class="bg-blue-100 rounded-full flex items-center justify-center w-16 h-16"
      >
        <icon-base width="30" height="30" icon="upload-file" />
      </div>
    </div>
    <input
      id="upload-box-browse"
      :accept="accept"
      class="hidden"
      data-cy="documents-upload-box"
      multiple
      type="file"
      @change="addFileFromBrowse"
    />
    <div class="max-w-[300px] text-center text-sm">
      <span>
        {{ $t("DEALS.DOCUMENTS.UPLOAD_TEXT_3", { docTypes: accept }) }}
      </span>
      <label for="upload-box-browse" class="text-blue-500 cursor-pointer">
        {{ ` ${$t("DEALS.DOCUMENTS.UPLOAD_TEXT_BROWSE")} ` }}
      </label>
      <span>{{ $t("DEALS.DOCUMENTS.UPLOAD_TEXT_2") }}</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
defineProps<{
  accept: string;
}>();

const emit = defineEmits<{
  "files:add": [files: FileList];
}>();

const addFileFromBrowse = (event: Event) => {
  const files = (event.target as HTMLInputElement)?.files;

  if (!files?.length) {
    return;
  }

  emit("files:add", files);

  // Set the input value to null because otherwise
  // onChange won't work next time you select the same file.
  (event.target as HTMLInputElement).value = "";
};

const addFileFromDragEvent = (event: DragEvent) => {
  if (!event?.dataTransfer) {
    return;
  }

  emit("files:add", event.dataTransfer.files);
};
</script>
